import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import clsx from 'clsx';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { object, string } from 'yup';
import 'yup-phone';
import { AnimatePresence, motion } from 'framer-motion';
import Typography from '../../components/Typography';
import Layout from '../../components/Layout';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CardBody from '../../components/CardBody';
import LoadingWrapper from '../../components/LoadingWrapper';
import Input from '../../components/Input';
import Breadcrumbs from '../../components/Breadcrumbs';
import Grid from '../../components/Grid';
import trackCapterraEvent from '../../utils/trackCapterraEvent';
import trackAdwordsEvent from '../../utils/TrackAdwordsEvent';
import formatLocale from '../../utils/formatLocale';

const schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  phone_number: string().required(),
  email: string().email().required(),
  company: string().required(),
  reason: string(),
});

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const ContactTemplate = ({
  title,
  lead,
  usps,
  seo,
  logos,
  reason,
  location,
  trackCapterra,
  locale,
  localizations,
  translations,
  slug,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const isMobile = useMedia('(max-width: 991px)');

  const handleSubmitForm = async data => {
    setIsSubmitting(true);
    try {
      await axios.post('/', encode({ 'form-name': 'contact', ...data }), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('track', 'Contact');
      }
      if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof gtag === 'function') {
        // eslint-disable-next-line no-undef
        gtag('event', 'generate_lead', {
          event_category: 'engagement',
          event_label: 'demo-request',
        });
      }

      trackAdwordsEvent('conversion', {
        send_to: trackCapterra ? 'AW-10828219545/FYHICIvUuosDEJmJpqso' : 'AW-10828219545/MLdHCIDLy4sDEJmJpqso',
      });

      if (trackCapterra) {
        trackCapterraEvent();
      }

      setIsSubmitted(true);

      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout location={location} locale={locale} localizations={localizations} slug={slug}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: 'description',
            content: seo?.description,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
      </Helmet>
      <main className={clsx({ 'bg-gray-100': isMobile })}>
        <Grid container>
          <Breadcrumbs location={location} locale={locale} className="pt-24 mb-24 mb-lg-0" />
        </Grid>
        <section className="container-lg mb-lg-40">
          <Grid row>
            <Grid column xs={12} lg={5} className="pb-lg-80 pt-lg-72">
              <div className="container container-fluid-lg px-lg-0 text-center text-lg-left">
                <Typography variant="h2" component="h1" className="mb-15">
                  {title}
                </Typography>
                <Typography variant="subtitle-1" className="mb-32 mb-sm-48 mb-lg-32">
                  {lead}
                </Typography>
                <hr className="my-0 d-none d-lg-block" />
                <div className="d-flex mx-n12 flex-wrap flex-column flex-md-row flex-lg-column align-items-start justify-content-center align-items-lg-start mt-lg-32">
                  {usps.map(usp => (
                    <div className="d-flex align-items-center mb-12 px-12" key={usp}>
                      <Icon icon={faCheckCircle} className="text-success mr-12" />
                      <Typography color="gray-800" variant="subtitle-1" align="left">
                        {usp}
                      </Typography>
                    </div>
                  ))}
                </div>
                {logos?.length > 0 && (
                  <>
                    <hr className="my-32 my-sm-48 my-lg-32 d-none d-lg-block" />
                    <Grid row className="justify-content-center justify-content-lg-start">
                      {logos.slice(0, 3).map(logo => (
                        <Grid column xs="auto" className="d-flex align-items-center">
                          <a href={logo?.link} title={logo?.title} target="_blank" rel="noreferrer">
                            <img src={`${logo?.url}?w=110 1x, ${logo?.url}?w=220 2x`} alt={logo?.title} width={100} />
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </div>
            </Grid>
            <Grid column xs={12} lg={7}>
              <div className="partner-form-bg mx-n15 mx-lg-0">
                <div className="container container-fluid-lg px-lg-0">
                  <Card elevation={5} className="mb-56 mt-48 mb-sm-80 mt-sm-64 mb-lg-56 mt-lg-56">
                    <CardBody>
                      <LoadingWrapper isLoading={isSubmitting}>
                        <AnimatePresence>
                          {isSubmitted && (
                            <motion.div
                              key="overlay"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.15, ease: 'easeOut', type: 'tween' }}
                            >
                              <div className="partner-form-overlay">
                                <Icon icon={faCheckCircle} size="4x" className="text-success mb-16" />
                                <Typography variant="h3" component="div" align="center">
                                  {translations.feedback.success}
                                </Typography>
                              </div>
                            </motion.div>
                          )}
                          {isError && (
                            <motion.div
                              key="error-overlay"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.15, ease: 'easeOut', type: 'tween' }}
                            >
                              <div className="partner-form-overlay">
                                <Icon icon={faTimesCircle} size="4x" className="text-danger mb-16" />
                                <Typography variant="h4" component="div" align="center" className="mb-32">
                                  {translations.feedback.error}
                                </Typography>
                                <Button onClick={() => setIsError(false)}>{translations.actions.close}</Button>
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                        <form
                          name="contact"
                          method="post"
                          onSubmit={handleSubmit(handleSubmitForm)}
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                        >
                          <div className="form-group">
                            <Input
                              type="text"
                              label={translations.fields.firstName}
                              id="first_name"
                              name="first_name"
                              ref={register()}
                              errors={errors}
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              type="text"
                              label={translations.fields.lastName}
                              id="last_name"
                              name="last_name"
                              ref={register()}
                              errors={errors}
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              type="email"
                              label={translations.fields.email}
                              id="email"
                              name="email"
                              ref={register()}
                              errors={errors}
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              type="tel"
                              label={translations.fields.phoneNumber}
                              id="phone-number"
                              name="phone_number"
                              ref={register()}
                              errors={errors}
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              type="text"
                              label={translations.fields.company}
                              id="company"
                              name="company"
                              ref={register()}
                              errors={errors}
                            />
                          </div>
                          <div className="form-group">
                            <input type="hidden" name="reason" ref={register()} value={reason} />
                            <p className="d-none">
                              <label>
                                Don’t fill this out if you’re human: <input name="bot-field" />
                              </label>
                            </p>
                            <Button type="submit" color="primary" block size="lg">
                              {translations.actions.submit}
                            </Button>
                          </div>
                        </form>
                      </LoadingWrapper>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
      </main>
    </Layout>
  );
};

ContactTemplate.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  lead: PropTypes.string,
  usps: PropTypes.arrayOf(PropTypes.string),
  reason: PropTypes.string,
  seo: PropTypes.object,
  logos: PropTypes.array,
  locale: PropTypes.string,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  trackCapterra: PropTypes.bool,
  translations: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.string),
    feedback: PropTypes.objectOf(PropTypes.string),
    actions: PropTypes.objectOf(PropTypes.string),
  }),
  slug: PropTypes.string,
};

ContactTemplate.defaultProps = {
  location: undefined,
  title: 'Neem contact op!',
  lead: 'Heb je vragen over of interesse in Afosto? Neem dan contact op via het contactformulier op deze pagina.',
  locale: 'en',
  localizations: [],
  usps: ['Nederlandse support bij het instellen', 'Integratie voor elk platform', '100% tevredenheid gegarandeerd'],
  reason: 'contact opnemen',
  seo: {
    title: 'Contact | Afosto',
    description: 'Neem contact op met Afosto voor premium Nederlandse support.',
  },
  logos: [],
  trackCapterra: false,
  translations: {},
  slug: undefined,
};

const ContactPage = ({ data, location }) => {
  const { frontmatter, fields } = data?.markdownRemark || {};

  return (
    <ContactTemplate
      title={frontmatter?.contactPage?.title}
      lead={frontmatter?.contactPage?.lead}
      usps={frontmatter?.contactPage?.usps}
      reason={frontmatter?.contactPage?.reason}
      seo={frontmatter?.seo}
      logos={frontmatter?.contactPage?.logos}
      location={location}
      locale={frontmatter?.locale}
      localizations={frontmatter?.localizations}
      trackCapterra={frontmatter?.contactPage?.trackCapterra}
      translations={{
        fields: frontmatter?.contactPage?.fields,
        feedback: frontmatter?.contactPage?.feedback,
        actions: frontmatter?.contactPage?.actions,
      }}
      slug={fields?.slug}
    />
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
};

ContactPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
      fields: {},
    },
  },
  location: undefined,
};

export default ContactPage;

export const pageQuery = graphql`
  query contactPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        locale
        localizations {
          locale
          slug
        }
        seo {
          title
          description
        }
        contactPage {
          title
          lead
          usps
          reason
          trackCapterra
          logos {
            title
            url
            link
          }
          fields {
            company
            phoneNumber
            lastName
            firstName
            email
          }
          feedback {
            error
            success
          }
          actions {
            close
            submit
          }
        }
      }
    }
  }
`;
